// Project soundcloud embed
#audio {
    background-color: #2a2827;
    padding: 5px;
    padding-bottom: 0px;
    margin-bottom: 30px;
}

// Project soundcloud embed
#audio iframe {
    height: 20px;
    width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

// Project video embed
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive::before {
    display: block;
    content: "";
    padding-top: 56.25%; /* 16:9 aspect ratio */
}
.embed-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Project multipage navigation
#bottom-nav {
    display: flex;
    justify-content: space-between;
    font-size: larger;
}

// Rounded horizontal rule for project multipages
hr.rounded {
    margin-top: 20px;
    border: 1px solid grey;
    border-radius: 8px;
  }

// Custom Emoji Display
#emoji {
    display: inline-block; 
    vertical-align: middle; 
    width: 1.2em; 
    height: 1.2em;
}

// Footer Socials
.social .contact-icons{
    padding-top: 1rem;
    font-size: 2.7rem;
}

// Home page tables standardist date column width 
// TODO: specify with ids maybe
.table-responsive th:first-child,
.table-responsive td:first-child {
  width: 16.5%;
}

// No URL underline in tables
a:hover, table.table a:hover {
    text-decoration: none;
}

// Table links not coloured for blogs and projects
#blog-post-home a{
    color: var(--global-text-color);
    &:hover{
        color: var(--global-theme-color);
    }
}

// Code block padding
.highlight {
    padding-top: 10px;
    padding-bottom: 10px;
}

.highlight pre {
    background-color: var(--global-bg-color) !important;
    
}

code span {
  font-family: 'Fira Mono', monospace !important;
}


pre {
    
    padding: 8px 12px;
    position: relative;
  
    /* Copy code to clipboard button */
    .copy {
      background: var(--global-card-bg-color);
      border-color: var(--global-bg-color);
      border-radius: .3rem;
      border-style: solid;
      color: var(--global-text-color);
      font-size: medium;
      opacity: 0.2;
      position: absolute;
      right: .25rem;
      top: .25rem;
  
      &:active,
      &:focus,
      &:hover {
        color: var(--global-hover-color);
        opacity: 1;
      }
    }
  
    &:active .copy,
    &:focus .copy,
    &:hover .copy {
      color: var(--global-hover-color);
      opacity: 1;
    }

    /* Go to gist URL */
    .github {
      background: var(--global-card-bg-color);
      border-color: var(--global-bg-color);
      border-radius: .3rem;
      border-style: solid;
      color: var(--global-text-color);
      font-size: medium;
      opacity: 0.2;
      position: absolute;
      right: 2rem;
      top: .25rem;
  
      &:active,
      &:focus,
      &:hover {
        color: var(--global-hover-color);
        opacity: 1;
      }
    }
    &:active .github,
    &:focus .github,
    &:hover .github {
      color: var(--global-hover-color);
      opacity: 1;
    }
  }